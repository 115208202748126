<template>
  <SlDropdown
    v-bind="$attrs"
    placement="bottom-end"
  >
    <template #target>
      <SlButton
        variant="tertiary"
        color="grey"
        size="xs"
        icon
      >
        <icon
          data="@icons/vertical-dots.svg"
          class="size-20 color-grey"
        />
      </SlButton>
    </template>
    <template #content>
      <SlDropdownOption
        v-for="action in allowedActions"
        :key="action.flag"
        select-close
        @select="action.action(project)"
      >
        <template #prepend>
          <icon
            v-if="action.icon"
            class="fill-off size-16 stroke-icon--grey"
            :data="getIcon(action.icon)"
          />
        </template>
        {{ action.label }}
      </SlDropdownOption>
    </template>
  </SlDropdown>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { fileSaver } from '@/mixins/webAPI';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { projectActionTypes } from '@/config/project';
import { routeNames } from '@/config/router/router.config';

export default {
  name: 'ProjectActionsDropdown',
  mixins: [fileSaver, modal],
  props: {
    actions: {
      type: Number,
      required: true
    },
    project: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      actionsConfig: [
        {
          flag: 0,
          label: this.$t('Web.Project.StopProject'),
          icon: 'stop-project',
          action: (project) => this.callAction({
            project,
            action: this.requestProjectStop,
            type: projectActionTypes.STOP
          })
        },
        {
          flag: 1,
          label: this.$t('Web.Project.Start'),
          icon: 'start-project',
          action: (project) => this.callAction({
            project,
            action: this.handleProjectStart,
            type: projectActionTypes.START
          })
        },
        {
          flag: 7,
          label: this.$t('Web.Project.Rename'),
          icon: 'pen',
          action: (project) => this.callAction({
            project,
            action: this.openRenameModal,
            type: projectActionTypes.RENAME
          })
        },
        {
          flag: 2,
          label: this.$t('Web.Project.Download'),
          icon: 'download',
          action: (project) => this.callAction({
            project,
            action: this.downloadProject,
            type: projectActionTypes.DOWNLOAD
          })
        },
        {
          flag: 3,
          label: this.$t('Web.Project.DownloadP'),
          icon: 'download',
          action: (project) => this.callAction({
            project,
            action: this.downloadPortableProject,
            type: projectActionTypes.DOWNLOAD_PORTABLE
          })
        },
        {
          flag: 4,
          label: this.$t('Web.Project.Archive'),
          icon: 'archive-project',
          action: (project) => this.callAction({
            project,
            action: this.handleProjectArchive,
            type: projectActionTypes.ARCHIVE
          })
        },
        {
          flag: 5,
          label: this.$t('Web.Project.Unarchive'),
          icon: 'unarchive',
          action: (project) => this.callAction({
            project,
            action: this.handleProjectUnarchive,
            type: projectActionTypes.UNARCHIVE
          })
        },
        {
          flag: 6,
          label: this.$t('Web.Project.Delete'),
          icon: 'delete',
          action: (project) => this.callAction({
            project,
            action: this.requestProjectDelete,
            type: projectActionTypes.DELETE
          })
        },
        {
          flag: 8,
          label: this.$t('Web.Project.VersionHistory'),
          icon: 'backups',
          action: (project) => this.callAction({
            project,
            action: this.openBackups,
            type: projectActionTypes.BACKUPS
          })
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      existingNames: 'manageProjects/existingNames',
      isFreePlan: 'initialization/isFreePlan'
    }),
    allowedActions() {
      return this.actionsConfig.filter(action => {
        return action.visible || this.actions & 1 << action.flag;
      });
    }
  },
  methods: {
    ...mapActions({
      handleProjectStop: 'manageProjects/handleProjectStop',
      handleProjectStart: 'manageProjects/handleProjectStart',
      handleProjectDownload: 'manageProjects/handleProjectDownload',
      handleProjectArchive: 'manageProjects/handleProjectArchive',
      handleProjectUnarchive: 'manageProjects/handleProjectUnarchive',
      handleProjectDelete: 'manageProjects/handleProjectDelete',
      handleProjectRename: 'manageProjects/handleProjectRename'
    }),
    getIcon(icon) {
      return require(`@icons/${icon}.svg`);
    },
    callAction({ project, action, type }) {
      action(project);

      this.$emit('action', {
        actionType: type,
        project
      });
    },
    openBackups({ id }) {
      this.$router.push({
        name: routeNames.BACKUPS,
        params: {
          originId: id
        }
      });
    },
    requestProjectStop(project) {
      if (project.exampleId || this.isFreePlan) {
        return this.handleProjectStop(project);
      }

      if (project.originId) {
        this.showModal(modalsId.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Modals.ActionConfirm.TitleStopTimeMachine'),
          text: this.$t('Web.Modals.ActionConfirm.TextStopTimeMachine'),
          icon: 'style_warning_double',
          confirmText: this.$t('Web.Modals.ActionConfirm.ButtonStop'),
          confirmCallback: this.stopTimeMachine
        });
      }

      const modalText = `${this.$t('Web.Project.StopConfirm')} ${this.$t('Web.Modals.SureProceed')}`;

      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Project.StopProject'),
        text: modalText,
        icon: 'style_warning',
        confirmText: this.$t('Web.Project.Stop'),
        confirmCallback: this.handleProjectStop.bind(this, project)
      });
    },
    requestProjectDelete(project) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.Modals.ActionConfirm.TitleDeleteProject'),
        text: this.$t('Web.Modals.ActionConfirm.TextDeleteProject'),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.Modals.ActionConfirm.ButtonDelete'),
        confirmCallback: this.handleProjectDelete.bind(this, project)
      });
    },
    downloadProject({ id } = {}, portable) {
      this.saveFile(this.handleProjectDownload.bind(this, { id, portable }));
    },
    downloadPortableProject(project) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Common.AreYouSure'),
        text: this.$t('Web.Project.DownloadPWarning'),
        icon: 'style_warning',
        confirmText: this.$t('Web.Project.DownloadP'),
        confirmCallback: () => this.downloadProject(project, true)
      });
    },
    openRenameModal(project) {
      this.showModal(modalsId.SL_RENAME_MODAL, {
        text: project.name,
        validationRules: {
          'sl_not_includes': {
            values: this.existingNames,
            message: this.$t('Web.Error.AlreadyExist', {
              1: this.$t('Web.Error.Names.Project')
            })
          }
        },
        title: this.$t('Web.Modals.Rename.Title', { 1: this.$t('Web.Modals.Rename.Project') }),
        saveCallback: (name) => this.handleProjectRename({
          id: project.id,
          name
        })
      });
    }
  }
};
</script>
